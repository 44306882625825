import { Box, Grid, Typography, Button } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import FinalQandA from "./FinalQandA";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import appContext from "../../context/AppContext";
import axios from "axios";
import { environment } from "../../config";
import AWS from "aws-sdk";
import { S3, PutObjectCommand } from '@aws-sdk/client-s3';
import { Oval } from "react-loader-spinner";
import Feedback from "../../images/feedback.svg";
import Information from "../../images/information.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

window.Buffer = window.Buffer || require("buffer").Buffer;

const FinalQuestionAnswer = () => {
    const AppContext = useContext(appContext);
    const [questionNumber, SetQuestionNumber] = useState(
        AppContext?.interview_data?.currentQuestion
            ? parseInt(AppContext?.interview_data?.currentQuestion)
            : 0
    );
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [screenShare, setScreenShare] = useState(true);
    const [loader, setLoader] = useState(true);
    const [progress, setProgress] = React.useState(10);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [code, setCode] = useState('// Write your code here');

    const handleEditorChange = (value, event) => {
        setCode(value);
    };
    const callLoader = () => {
        setLoader(false);
        setTimeout(() => {
            setLoader(true);
            setProgress((prevProgress) => prevProgress + 10);
        }, 2000);
    };

    // useEffect(() => {
    //     // AppContext?.interview_data?.interview_questions[questionNumber]?.screen_share === "true" ? true : false
    //     setScreenShare(true);
    // }, [
    //     AppContext?.interview_data?.interview_questions[questionNumber]
    //         ?.screen_share,
    // ]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, []);

    const handleKeydown = (event) => {
        document.addEventListener('keydown', handleKeydown);
        if (event.key === 'Escape') {
            event.preventDefault();
            console.log('Preventing exit from full screen mode.');
        }
    };

    const saveAnswer = async (question_id, mediaBlobUrl) => {
        console.log('I AM CALLED');
        saveResponse(question_id, mediaBlobUrl);
        //   await axios.post(environment.updateApplicant, { currentQuestion: questionNumber >= (AppContext?.interview_data?.question?.length - 1) ? 0 : questionNumber + 1 });
        SetQuestionNumber(questionNumber + 1);
    };
    const saveResponse = async (question_id, mediaBlobUrl) => {
        let s3Resp;
        let questionType =
            AppContext?.interview_data?.interview_questions[questionNumber]
                ?.question_type;
        if (questionType === "audio/video") {
            s3Resp = await s3ImageUpload(mediaBlobUrl);
        } else {
            s3Resp = mediaBlobUrl;
        }
        await axios
            .post(environment.updateApplicant, {
                question_id: question_id._id,
                // answer_url: questionType === "audio/video" ? s3Resp : mediaBlobUrl,
                answer_url: s3Resp,
            })
            .then((res) => {
                // console.log("resposne network call", res)
            });
        await axios
            .post(environment.updateCount, { count: questionNumber })
            .then((res) => {
                // console.log(res);
            });
        AppContext.setUploadProgress(
            questionNumber,
            s3Resp
            // questionType === "audio/video" ? s3Resp : mediaBlobUrl
        );
        // const isLastQuestion =
        //   questionNumber >=
        //   AppContext?.interview_data?.interview_questions?.length - 1;
        // if (isLastQuestion) {
        //   navigate("/review");
        // }
    };

    const s3ImageUpload = async (mediaBlobUrl) => {
        const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
        const file = new File([audioBlob], `${Date.now()}.mp4`, {
            type: audioBlob.type,
        });

        const credentials  = {
            accessKeyId: process.env.REACT_APP_ACCESS,
            secretAccessKey: process.env.REACT_APP_SECRET,
        };

        const bucketName = process.env.REACT_APP_BUCKET_NAME;
        const objectKey = file.name;
        const videoFilePath = file;

        const s3 = new S3({
            apiVersion: '2006-03-01',
            signatureVersion: 'v4',
            region: process.env.REACT_APP_REGION,
            credentials
        });

        const params = {
            Bucket: bucketName,
            Key: objectKey,
            Body: videoFilePath,
            ContentType: file.type,
        };

        try {
            const data = await s3.send(new PutObjectCommand(params));
            const objectUrl = `https://${params.Bucket}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/${params.Key}`;
            return objectUrl;
        } catch (error) {
            console.error('Error uploading video to S3:', error);
        }

    };

    const exit = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid className="StickyContentQA">
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        left: "1%",
                        position: "relative",
                    }}
                >
                    Video interview for {AppContext?.interview_data?.title}
                </Typography>
                <Button
                    variant="contained"
                    className="primaryBtn"
                    onClick={exit}
                    style={{
                        background: "#cc0000 !important",
                        right: "1%",
                        position: "relative",
                    }}
                >
                    Exit Interview Process
                </Button>
                <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {" Are you sure want to Exit Interview?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose}>No</Button>
                        <Button
                            onClick={handleClose}
                            href={`/${AppContext.token}`}
                            autoFocus
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <div
                // style={{ maxWidth: "100vh" }}
            >
                {/* <Card sx={{ padding: "15px 10px", boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px", borderRadius: "8px", pb: 0 }}> */}
                {screenShare ? (
                    <div>
                        {loader ? (
                            <CardContent
                                style={{
                                    height: "42vh",
                                }}
                            >
                                {AppContext?.interview_data?.interview_questions?.length >
                                    0 && (
                                        <FinalQandA
                                            saveAnswer={saveAnswer}
                                            question={
                                                AppContext?.interview_data?.interview_questions[
                                                    questionNumber
                                                    ]
                                            }
                                            question_id={
                                                AppContext?.interview_data?.interview_questions._id
                                            }
                                            questionNumber={questionNumber}
                                            totalQuestionCount={
                                                AppContext?.interview_data?.interview_questions?.length
                                            }
                                            isLastQuestion={
                                                questionNumber >=
                                                AppContext?.interview_data?.interview_questions?.length -
                                                1
                                            }
                                            callLoader={callLoader}
                                        />
                                    )}
                            </CardContent>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <div style={{ marginTop: "15%" }}>
                                    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                                        Your answer is being submitted, please wait
                                    </Typography>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            padding: "10px",
                                        }}
                                    >
                                        <Oval
                                            height={80}
                                            width={80}
                                            color="#1976d2"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="oval-loading"
                                            secondaryColor="#1976d2"
                                            strokeWidth={2}
                                            strokeWidthSecondary={2}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <Box>
                        <CardContent
                            sx={{ padding: "30px", margin: "20px", "text-align": "center" }}
                        >
                            <Typography
                                sx={{ color: "#23272A", fontSize: "18px", fontWeight: "600" }}
                            >
                                Next Question need your screen share permission,<br></br> please
                                allow the permission at the time of answer.
                            </Typography>
                            <Box sx={{ pt: 5 }}>
                                <Button
                                    variant="contained"
                                    className="primaryBtn"
                                    onClick={() => {
                                        setScreenShare(true);
                                    }}
                                >
                                    Proceed
                                </Button>
                            </Box>
                        </CardContent>
                    </Box>
                )}
                {/* </Card> */}
            </div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    "line-height": "25px",
                    "margin-top": "15%",
                    marginLeft: "1%",
                    marginRight: "1%",
                }}
            >
                <div style={{ justifyContent: "left" }}>
                    <img src={Feedback} style={{ width: "20px", height: "12px" }} />
                    <span className="lowerTextLeft">
            Feedback or suggestions: hello@airec.io
          </span>
                </div>
                <div style={{ justifyContent: "right" }}>
                    <img src={Feedback} style={{ width: "20px", height: "12px" }} />
                    <span className="lowerTextLeft">
            Note: Interviews audio and videos will be saved within our servers
            for a period of 3 months only
          </span>
                </div>
            </Box>
        </Grid>
    );
};

export default FinalQuestionAnswer;
