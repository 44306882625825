import { Box, Grid, Typography, Button } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import QandA from './QandA';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import appContext from "../../context/AppContext";
import { Oval } from 'react-loader-spinner'
import Feedback from '../../images/feedback.svg'



window.Buffer = window.Buffer || require("buffer").Buffer;

const options = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS,
    secretAccessKey: process.env.REACT_APP_SECRET,
}

const QuestionAnswer = () => {
    const [questionNumber, SetQuestionNumber] = useState(0);
    const [open, setOpen] = useState(false);
    const [screenShare, setScreenShare] = useState(true)
    const AppContext = useContext(appContext);
    const [loader, setLoader] = useState(true)
    const callLoader = () => {
        setLoader(false)
        setTimeout(() => {
            setLoader(true);
        }, 2000);
    }


    const queArr = [
        {
            "title": "Tell me about your self",
            "reading_time": "10",
            "time_duration": "10",
            "order_no": 1,
            "screen_share": "false"
        },
        {
            "title": "What is array",
            "reading_time": "10",
            "time_duration": "10",
            "order_no": 2,
            "screen_share": "false"
        },

    ]

    useEffect(() => {
        setScreenShare(queArr[questionNumber]?.screen_share === "true" ? false : true);
    }, [queArr[questionNumber]?.screen_share])


    const saveAnswer = () => {
        SetQuestionNumber(questionNumber + 1);
    };

    const exit = () => { setOpen(true); };

    const handleClose = () => { setOpen(false); };

    return (
        <Grid className='StickyContentQA'>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>Welcome to the Practice Interview</Typography>
                <Button variant="contained" className='primaryBtn' onClick={exit} style={{background: '#cc0000 !important'}}>
                    Exit Interview Process
                </Button>
                <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        {" Are you sure want to Exit Interview?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose}>No</Button>
                        <Button onClick={handleClose} href={`/${AppContext.token}`} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <div style={{ maxWidth: '1200px'}}>
                {/* <Card sx={{ padding: "15px 10px", boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px", borderRadius: "8px" }}> */}
                    {screenShare ? <div>
                        {loader ? <CardContent 
                    style={{
                        height : '40vh'
                    }}>
                            {queArr.length > 0 &&
                                <QandA
                                    saveAnswer={saveAnswer}
                                    question={queArr[questionNumber]}
                                    questionNumber={questionNumber}
                                    isLastQuestion={questionNumber >= (queArr.length - 1)}
                                    totalQuestionCount={queArr.length}
                                    callLoader={callLoader}
                                />
                            }
                        </CardContent>
                            : <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                            <div style={{ margin: "20px -350px 30px 50px"}}>
                                <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>Your answer is being submitted, please wait</Typography>
                                <div style={{ display: "flex", justifyContent: 'center', padding: "10px" }}>
                                    <Oval
                                        height={80}
                                        width={80}
                                        color="#1976d2"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#1976d2"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}         
                                    />
                                </div>
                            </div>
                        </div>}
                    </div> :
                        <Box>
                            <CardContent sx={{ padding: "30px", margin: "20px", "text-align": "center" }}>
                                <Typography sx={{ color: "#23272A", fontSize: "18px", fontWeight: "600" }}>Next Question need your screen share permission,<br></br> please allow the permission at the time of answer.</Typography>
                                <Box sx={{ pt: 5 }}>
                                    <Button variant="contained" className='primaryBtn' onClick={() => { setScreenShare(true) }}>
                                        Ok
                                    </Button>
                                </Box>
                            </CardContent>
                        </Box>}
                {/* </Card> */}
            </div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', "line-height": "25px", "margin-top": "270px" }}>
                <div style={{ justifyContent: 'left'}}><img src={Feedback}  style={{ width: '20px', height: '12px' }}/><span className='lowerTextLeft'>Feedback or suggestions: hello@airec.io</span></div>
                <div style={{ justifyContent: 'right' }}><img src={Feedback}style={{ width: '20px', height: '12px'}}/><span className='lowerTextLeft'>Note: Interviews audio and videos will be saved within our servers for a period of 3 months only</span></div>
            </Box>
        </Grid >
    )
}

export default QuestionAnswer