import { Box, Grid, Typography, Button } from '@mui/material';
import React, { useState, useEffect, useContext} from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';


const Interview = () => {
 
    const navigate = useNavigate();

    const interview = () => {
        navigate('/QuestionAnswer');
    }

    return (
        <Grid className='StickyContent'>
            {/* <Box sx={{ display: "flex", justifyContent: "flex-end", }}>
                <Box >
                    <Button variant="contained" className='primaryBtn' href="https://airec.io/">
                        Exit Interview Process
                    </Button>
                </Box>
            </Box> */}
            <Box sx={{ textAlign: "center" }}>
                <Typography sx={{ margin: { sm: "20px 0px", xs: "10px 0px" }, color: "#23272A", fontSize: { sm: "24px", xs: "18px" } }}>You are about to start your video interview for</Typography>
                <Typography variant='h5' sx={{ margin: "20px 0px", color: "#23272A", fontWeight: 600 }}>Frontend developer</Typography>
                <Typography sx={{ margin: { sm: "20px 0px", xs: "10px 0px" }, color: "#23272A", fontSize: { sm: "24px", xs: "18px" } }}>When you are ready click here</Typography>
                <Button variant="contained" sx={{ mr: 1 }} className="secondaryBtn" onClick={interview} >
                    Start Interview
                </Button>
            </Box>
        </Grid>
    )
}


export default Interview