import { Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useState, useContext } from "react";
import { environment } from "../../config";
import axios from "axios";
import Modal from "@mui/material/Modal";
import appContext from "../../context/AppContext";

const FeedBack = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 3,
  };
  const AppContext = useContext(appContext);

  const [value, setValue] = React.useState(0);
  const [textArea, setTextArea] = useState("");
  const [res, setRes] = useState("");
  const submitFeedback = async () => {
    await axios
      .post(environment.feedBack, { remarks: textArea, rating: value })
      .then((res) => {
        setRes(res?.data?.data);
      })
      .then(() => {
        handleOpen();
      });
    // setValue(0);
    setTextArea("");
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Grid>
        {/* <Card sx={{ padding: "15px 10px", borderRadius: "8px", textAlign: "center", boxShadow: "none" }}> */}
        <Box
          style={{
            borderRadius: "8px",
            padding: "10px",
            textAlign: "center",
            margin: "20px auto",
          }}
        >
          <Box sx={{ mt: 2 }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={7}>
                <Typography style={{ float: "right" }}>
                  Let us know was your interview experience?
                </Typography>
                {/* <video style={{ height: '200px', width: '100%', "border-radius": "15px", marginTop: '-10px', objectFit: 'cover' }} controls autoPlay src={videoSrc} ></video> */}
              </Grid>
              <Grid
                xs={5}
                // sx={{ padding: "20px 15px" }}
              >
                {/* <Typography >{u?.title}</Typography> */}
                <Rating
                  style={{
                    float: "left",
                    fontSize: "25px",
                    marginTop: "5px",
                    color: "#3BABDB",
                  }}
                  name="simple-controlled"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  size="large"
                />
              </Grid>
            </Grid>
            {/* <Typography >Let us know was your interview experience?</Typography> */}
            {/* <Box sx={{ m: "10px auto", background: "#f5f8ff", borderRadius: "40px", p: "10px", display: "flex", justifyContent: "center", width: "225px" }}> */}
            {/* <Rating
                                name="simple-controlled"
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                size="large"
                            /> */}
            {/* </Box> */}
          </Box>
          <Box>
            <Box sx={{ mt: "20px" }}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="Write a review here..."
                style={{
                  width: 300,
                  height: 100,
                  borderRadius: "10px",
                  padding: " 10px",
                  border: "1px solid #ced4da",
                  background: AppContext?.isDarkMode ? "#282a2e" : "#ffffff",
                  color: AppContext?.isDarkMode ? "#ffffff" : "#000000",
                }}
                onChange={(event) => {
                  setTextArea(event.target.value);
                }}
                value={textArea}
              />
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              className="secondaryBtn"
              onClick={submitFeedback}
            >
              Submit
            </Button>
          </Box>
        </Box>
        <Box>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-description"
                  sx={{ m: 2, color: "green" }}
                >
                  {res}
                </Typography>
              </Box>
            </Modal>
          </div>
        </Box>
        {/* </Card> */}
      </Grid>
    </div>
  );
};

export default FeedBack;
