import { Box, Grid, Typography, Button } from '@mui/material';
import React, { useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';
import appContext from "../../context/AppContext";

const ReviewPractice = () => {
    const navigate = useNavigate();
    const AppContext = useContext(appContext);
    let temp = `/${AppContext.token}`
    return (
        <Grid className='StickyContent'>
            <Card sx={{ padding: "15px 10px", boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px", borderRadius: "8px" }}>
                <CardContent>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ textAlign: "center", margin: "0px 0px 30px 0px" }}>
                            <Typography sx={{ fontSize: "25px", marginTop: "10px", marginBottom: "25px", color: "#cc0000" }}><b>Thank you for Submitting the Practice Interview</b></Typography>
                            <Typography sx={{ fontSize: "25px", marginTop: "10px", marginBottom: "25px", }}><b>Now you can proceed for the Interview </b></Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                            <Button variant="contained" className="primaryBtn" onClick={() => { navigate('/FinalQuestionAnswer'); }}>Start Interview</Button>
                            <Button style={{ "margin-left": "5px" }} variant="contained" className="secondaryBtn" onClick={() => { navigate(temp); }}>Back To Home Page</Button>

                        </Box>


                    </Box>
                </CardContent>
            </Card>
        </Grid>
    )
}


export default ReviewPractice