import {
  Box,
  Grid,
  Typography,
  Button,
  Tooltip,
  Card,
  Divider,
} from "@mui/material";
import React, { useEffect, useContext } from "react";
import HorizontalLinearStepper from "../layout/Stepper";
import { useNavigate, useParams } from "react-router-dom";
import appContext from "../../context/AppContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const MainContainer = () => {
  const AppContext = useContext(appContext);
  const navigate = useNavigate();
  const { token } = useParams();
  // console.log("token====>", token);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);

  useEffect(() => {
    AppContext?.setToken(token);
  }, []);

  const practiceInterview = () => {
    setOpen(true);
  };
  const interview = () => {
    setOpen2(true);
  };

  const startInterview = () => {
    handleClose2();
    setOpen3(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose3 = () => {
    setOpen2(false);
  };
  const practiceStart = () => {
    navigate("/QuestionAnswer");
  };
  const interviewStart = () => {
    enterFullScreen();
    navigate("/FinalQuestionAnswer");
  };

  const enterFullScreen = () => {
    let document = window.document;
    document.body.classList.add("fullscreen-enable");
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
    }
  };
  return (
    <Grid className="StickyContent">
      <Box
        sx={{
          marginBottom: "50px",
        }}
      >
        <Typography
          align="center"
          sx={{
            fontSize: { sm: "22px", xs: "16px" },
            margin: "40px 0px 20px 0px",
          }}
        >
          Welcome to airec video interview platform
        </Typography>
        {/* AppContext?.interview_data?.welcome_note */}
        <Typography
          align="center"
          sx={{
            fontSize: { sm: "20px", xs: "16px" },
            margin: "10px 0px 0px 0px",
          }}
        >
          Video Interview for
        </Typography>
        <Typography
          align="center"
          variant="h5"
          sx={{ fontWeight: 700, margin: "10px 0px 0px 0px" }}
        >
          {" "}
          {AppContext?.interview_data?.title}
        </Typography>
        {/* <Typography
          align="center"
          sx={{
            fontSize: { sm: "20px", xs: "16px" },
            margin: "10px 0px 0px 0px",
          }}
        >
          at Easy2Employ
        </Typography> */}

        {AppContext?.interview_data && (
          <Box sx={{ textAlign: "center" }}>
            {/*Practice Modal to start the mock interview*/}
            <Dialog
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: { borderRadius: 10 },
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Instructions"}
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    // color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon
                    sx={{ marginRight: "10px", color: "#3BABDB" }}
                  />
                  <Typography>
                    Test your internet and system settings, making sure your mic
                    and camera are enabled.
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon
                    sx={{ marginRight: "10px", color: "#3BABDB" }}
                  />
                  <Typography>
                    It is recommended to close other apps/windows/tabs and
                    silence any notifications.
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon
                    sx={{ marginRight: "10px", color: "#3BABDB" }}
                  />
                  <Typography>
                    Review the job description once more if you haven’t already.
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon
                    sx={{ marginRight: "10px", color: "#3BABDB" }}
                  />
                  <Typography>
                    Dress Professionally, keep nature posture, proper lighting
                    with a clear head and shoulder view.{" "}
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon
                    sx={{ marginRight: "10px", color: "#3BABDB" }}
                  />
                  <Typography>
                    Take your time to practice the test or jump to an interview
                    video session when ready.
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon
                    sx={{ marginRight: "10px", color: "#3BABDB" }}
                  />
                  <Typography>Enable screen sharing when requested.</Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon
                    sx={{ marginRight: "10px", color: "#3BABDB" }}
                  />
                  <Typography>
                    Please keep an eye on the timer and complete your response
                    within the given time.
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon
                    sx={{ marginRight: "10px", color: "#3BABDB" }}
                  />
                  <Typography>
                    You will not be able to return to the question once the
                    response time is over.{" "}
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon
                    sx={{ marginRight: "10px", color: "#3BABDB" }}
                  />
                  <Typography>
                    If disconnected during the interview you will be able to
                    join back from the same question.
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon
                    sx={{ marginRight: "10px", color: "#3BABDB" }}
                  />
                  <Typography>
                    For best experience and for effective interview, please use
                    Desktop.
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon
                    sx={{ marginRight: "10px", color: "#3BABDB" }}
                  />
                  <Typography>
                    In the end, please don’t forget to provide feedback.
                  </Typography>
                </div>{" "}
                <br />
                <Typography>Good Luck!</Typography>
              </DialogContent>
              <DialogActions style={{ justifyContent: "right" }}>
                <Button
                  onClick={practiceStart}
                  autoFocus
                  variant="contained"
                  sx={{ mr: 1 }}
                  className="primaryBtn"
                >
                  Proceed to Practice
                </Button>
              </DialogActions>
              <br />
            </Dialog>

            {/*Actual Modal to Start the Interview*/}
            <Dialog
              open={open2}
              onClose={handleClose2}
              PaperProps={{
                style: { borderRadius: 10 },
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Instructions"}
                <IconButton
                  aria-label="close"
                  onClick={handleClose2}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    // color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Divider />
              <DialogContent>
                <div>
                  <Typography>
                    We request to do a practise session first if not done
                    already.
                  </Typography>
                  <br />
                  <Typography>
                    Moving forward, you will be asked questions which are video
                    / audio based, MCQs or descriptive. These are all time-based
                    therefore please make sure you complete the answers within
                    the given time.
                  </Typography>
                  <br />
                  <Typography>
                    The interview questions will appear in full screen mode,
                    please do not exit full screen mode or you would be exited
                    from the interview as well.
                  </Typography>
                  <br />
                  <Typography>
                    You may review your answers in the end but cannot re-submit
                    them.
                  </Typography>
                </div>
              </DialogContent>
              <DialogActions style={{ justifyContent: "right" }}>
                <Button
                  onClick={startInterview}
                  autoFocus
                  variant="contained"
                  sx={{ mr: 1 }}
                  className="primaryBtn"
                >
                  Continue
                </Button>
              </DialogActions>
              <br />
            </Dialog>

            <Dialog
              open={open3}
              onClose={handleClose3}
              PaperProps={{
                style: { borderRadius: 10 },
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Instructions"}
                <IconButton
                  aria-label="close"
                  onClick={handleClose2}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    // color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Divider />
              <DialogContent>
                <div>
                  <Typography>AI & Data Consent</Typography>

                  <Typography style={{ fontSize: "15px", marginTop: "10px" }}>
                    <strong>Data Retention Consent:</strong> I consent to the
                    retention of my personal data for 6 months after the
                    recruitment process for future job opportunities. I
                    understand I can request deletion of my data at any time.
                  </Typography>

                  <Typography style={{ fontSize: "15px", marginTop: "10px" }}>
                    <strong>Data Sharing Consent:</strong> I consent to the
                    sharing of my personal data with third-party service
                    providers involved in the recruitment process. I understand
                    my data will be securely handled according to data
                    protection laws.
                  </Typography>

                  <Typography style={{ fontSize: "15px", marginTop: "10px" }}>
                    <strong>AI Analysis Consent:</strong> I consent to AI-driven
                    analysis of my application data, including video, audio, and
                    written assessments, as part of the recruitment process. I
                    understand that I can request a human review of any
                    AI-generated decisions.
                  </Typography>
                </div>
              </DialogContent>
              <DialogActions style={{ justifyContent: "right" }}>
                <Button
                  onClick={interviewStart}
                  autoFocus
                  variant="contained"
                  sx={{ mr: 1 }}
                  className="primaryBtn"
                >
                  {/* Start the Interview */}
                  Agreed & Start Interview
                </Button>
              </DialogActions>
              <br />
            </Dialog>
          </Box>
        )}
      </Box>

      {/* {AppContext?.interview_data &&
                <Card sx={{ padding: "15px 10px", boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px", borderRadius: "8px" }}>
                    <Grid container sx={{ margin: "30px 10px", position: "relative" }}>
                        <Grid item lg={6} md={12} sm={12} xs={12}>
                            <Box sx={{ display: "center", justifyContent: "center", alignItems: "center", minHeight: { lg: "300px", md: "0px", sm: "0px", xs: "0px" } }}>
                                <Box sx={{ width: "30%", padding: "10px", height: "30%" }}>
                                    <Typography sx={{ fontSize: { sm: "15px", xs: "10px" } }}>{AppContext?.interview_data?.job_description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Divider orientation="vertical" flexItem></Divider>
                        <Grid item style={{ padding : '0px 20px', justifyContent: "center", display: "center"}} >
                            <Box >
                                <Box >
                                    <Box sx={{ textAlign: "center" }}>
                                        <Typography sx={{ textAlign: "left", fontSize: { sm: "20px", xs: "16px" } }}>Welcome Message: </Typography>
                                        <Typography sx={{ textAlign: "left", fontSize: { sm: "20px", xs: "16px" } }}>{AppContext?.interview_data?.welcome_note}</Typography>
                                        
                                    </Box>
                
                                    <Tooltip title="Before start Interview You can go for the practise Interview">
                                        <Button variant="contained" sx={{ justifyContent: "center", mr: 1 }} className="primaryBtn" onClick={interview} >
                                            Start Interview
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Grid>                
                    </Grid>
                </Card>
            } */}

      {AppContext?.interview_data && (
        <Grid container sx={{ margin: "30px 10px", position: "relative" }}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Box
              sx={{
                display: "center",
                justifyContent: "center",
                alignItems: "center",
                minHeight: { lg: "300px", md: "0px", sm: "0px", xs: "0px" },
              }}
            >
              <iframe
                width="560"
                height="315"
                src={
                  AppContext?.interview_data?.intro_video
                    ? AppContext?.interview_data?.intro_video
                    : "https://www.youtube.com/embed/hyommGFFQlo"
                }
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Box>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Typography
              sx={{ textAlign: "left", fontSize: { sm: "20px", xs: "16px" } }}
            >
              Welcome Message:{" "}
            </Typography>
            <Typography
              sx={{ textAlign: "left", fontSize: { sm: "20px", xs: "16px" } }}
            >
              {AppContext?.interview_data?.welcome_note}
            </Typography>

            <Tooltip title="Before start Interview You can go for the practise Interview">
              <Button
                variant="outlined"
                sx={{
                  justifyContent: "center",
                  mr: 1,
                  position: "absolute !important",
                  bottom: "20px",
                }}
                className="outlinedBtn"
                onClick={practiceInterview}
              >
                Start Practice
              </Button>
            </Tooltip>
            <Tooltip title="Before start Interview You can go for the practise Interview">
              <Button
                variant="contained"
                sx={{
                  justifyContent: "center",
                  mr: 1,
                  position: "absolute !important",
                  bottom: "20px",
                  right: "270px",
                }}
                className="primaryBtn"
                onClick={interview}
              >
                Start Interview
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      )}

      <HorizontalLinearStepper />
    </Grid>
  );
};

export default MainContainer;
