import * as React from "react";
import { useState, useContext } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { Box, Grid, Button, Divider } from "@mui/material";
import Link from "@mui/material/Link";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Dialog from "@mui/material/Dialog";
import { useReactMediaRecorder } from "react-media-recorder";
import { useNavigate } from "react-router-dom";
import ReplayIcon from "@mui/icons-material/Replay";
import SpeedTest from "./SpeedTest";
import appContext from "../../context/AppContext";
// import InfoIcon from '@mui/icons-material/Info';
import RecordingState from "../../images/Recording_state.svg";

const steps = [
  "Welcome",
  "Bandwidth Test",
  "Audio Test",
  "Video Test",
  "Interview",
];

// This is Stepper Copmonent where we test All the required testing before starting the Interview
export default function HorizontalLinearStepper() {
  const {
    startRecording,
    stopRecording,
    mediaBlobUrl,
  } = useReactMediaRecorder({ audio: true });

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [open, setOpen] = React.useState(null);
  const [showAudio, setShowAudio] = useState(false);
  const [isButton, setIsButton] = useState(false);
  const [videoStream, setVideoStream] = useState();
  const [recordingState, setRecordingState] = useState(false);
  const videoRef = React.useRef(null);
  const AppContext = useContext(appContext);
  // Here in the useEffect we are checking that if all the test is complited success fully then
  // test model will not be open again
  React.useEffect(() => {
    if (localStorage.getItem("modal") === "true") {
    } else setOpen(true);
  });

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    setIsButton(true);
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
      setIsButton(true);
    }
    if (activeStep == 0) {
      // speedCheck();
      setIsButton(true);
    }
    if (activeStep === 1) {
      audioTest();
    }
    if (activeStep === 2) {
      videoTest();
    }
    if (activeStep === 3) {
      videoStream.getTracks().forEach(function(track) {
        track.stop();
      });
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const finalFun = () => {
    localStorage.setItem("modal", "true");
    setOpen(false);
  };

  const stopRecordingFunc = () => {
    stopRecording();
    setIsButton(false);
  };

  const audioTest = () => {
    setShowAudio(true);
    startRecording();
    setRecordingState(true);
  };

  const videoTest = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
        setVideoStream(stream);
      })
      .then(
        setTimeout(() => {
          setIsButton(false);
        }, 2000)
      )
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="practiceModal"
    >
      <Grid>
        <Box className="stepPractise">
          {AppContext?.interview_data?.title && (
            <>
              <Typography
                align="left"
                variant="h5"
                sx={{ fontWeight: 500, margin: "10px 10px 10px 10px" }}
              >
                {" "}
                {AppContext?.interview_data?.title + " - Video Interview"}
              </Typography>
              <Divider />
            </>
          )}
          {activeStep === steps.length - 1 ? (
            <React.Fragment>
              <Grid
                item
                sm={9}
                lg={12}
                xs={12}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  minHeight: { sm: "350px", xs: "200px" },
                  padding: "20px ",
                }}
              >
                {/* <Grid item sm={3} xs={12} sx={{ display: { sm: "block", xs: "none" }, boxShadow: "rgb(0 0 0 / -1%) 0px -2px 29px, rgb(0 0 0 / 16%) 3px 0px 6px -1px", padding: "20px", color: "#000" }}></Grid> */}

                {/* <Grid item sm={9} lg={12} xs={12} sx={{ display: "flex", color: "#262626", alignItems: "center", justifyContent:"center", minHeight: { sm: "350px", xs: "200px" }, padding: "20px " }}> */}
                <CheckCircleIcon
                  sx={{
                    fontSize: "45px",
                    marginRight: "10px",
                    color: "green",
                    fontWeight: "700",
                  }}
                />
                {/* <Box> */}
                <Typography
                  align="center"
                  sx={{
                    fontSize: { sm: "20px", xs: "16px" },
                    margin: "10px 0px 0px 0px",
                  }}
                >
                  Settings Configuration is Completed
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", margin: "10px 0px 30px 0px" }}
                >
                  You are all set for the interview, please finish this setup
                  and proceed with your interview
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>Good Luck!</Typography>

                {/* </Box> */}
                {/* </Grid> */}
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: "1px solid #bfbfbf",
                  padding: "10px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {/* <Box sx={{ display: "flex", marginBottom: "10px" }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <LiveHelpIcon sx={{ fontSize: '18px', color: "#000", marginRight: '3px' }} />
                                        <Link href="https://www.google.com/" target='_blank' mr={2} sx={{ fontSize: "12px", color: "#000" }}>Contact Support</Link>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <SupportAgentIcon sx={{ fontSize: '18px', color: "#000", marginRight: '3px' }} />
                                        <Link href="/Faq" target='_blank' mr={2} sx={{ fontSize: "12px", color: "#000" }}>FAQ</Link>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", flexWrap: "wrap" }}> */}
                {/* {isStepOptional(activeStep) && (
                                        <Button variant="contained" sx={{ mr: 1, mb: 1 }} className="secondaryBtn" onClick={handleSkip}>
                                            Skip Practice
                                        </Button>
                                    )} */}
                <Button
                  variant="contained"
                  onClick={finalFun}
                  className="primaryBtn"
                  sx={{ mb: 1 }}
                  style={{ marginTop: "-150px", float: "right", left: "670px" }}
                >
                  Finish
                  {/* <ArrowForwardIcon sx={{ fontSize: '18px', marginLeft: '5px' }} /> */}
                </Button>
                {/* </Box> */}
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid container>
                {/* <Grid item sm={3} xs={12} sx={{ boxShadow: "rgb(0 0 0 / -1%) 0px -2px 29px, rgb(0 0 0 / 16%) 3px 0px 6px -1px", padding: "20px", color: "#000" }}>
                                    {activeStep == 1 && (
                                        <Box sx={{ display: "flex", }}>
                                            <InfoIcon sx={{ color: '#f96d64', marginRight: "5px", fontSize: "18px" }} />
                                            <Typography style={{ fontSize: "12px", margin: "0px", }}>The Bandwidth Test checks the speed of your internet connection!</Typography>
                                        </Box>
                                    )}
                                    {activeStep == 2 && (
                                        <Box sx={{ display: "flex", }}>
                                            <InfoIcon sx={{ color: '#f96d64', marginRight: "5px", fontSize: "18px" }} />
                                            <Typography style={{ fontSize: "12px", margin: "0px", }}>The Audio Test checks the Audio Device is working properly or not!</Typography>
                                        </Box>
                                    )}
                                    {activeStep == 3 && (
                                        <Box sx={{ display: "flex", }}>
                                            <InfoIcon sx={{ color: '#f96d64', marginRight: "5px", fontSize: "18px" }} />
                                            <Typography style={{ fontSize: "12px", margin: "0px", }}>The Video Test checks the webcam is working properly or not!</Typography>
                                        </Box>
                                    )}
                                </Grid> */}
                {activeStep == 0 && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minHeight: { sm: "350px", xs: "200px" },
                      padding: "20px ",
                    }}
                  >
                    <Grid>
                      <Grid
                        container
                        sx={{ margin: "30px 10px", position: "relative" }}
                      >
                        <Grid item md={6} sm={6} xs={6}>
                          <Box
                            sx={{
                              display: "left",
                              justifyContent: "left",
                              alignItems: "left",
                              minHeight: {
                                lg: "350px",
                                md: "0px",
                                sm: "0px",
                                xs: "0px",
                              },
                            }}
                          >
                            {AppContext?.interview_data && (
                              <iframe
                                width="350"
                                height="290"
                                src={
                                  AppContext?.interview_data?.intro_video
                                    ? AppContext?.interview_data?.intro_video
                                    : "https://www.youtube.com/embed/hyommGFFQlo"
                                }
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></iframe>
                            )}
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontSize: { sm: "20px", xs: "16px" },
                            }}
                          >
                            WELCOME TO YOUR VIDEO INTERVIEW{" "}
                          </Typography>
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontSize: { sm: "16px", xs: "12px" },
                              margin: "12px 0px 12px 0px",
                            }}
                          >
                            {
                              "Before we begin, we need to check your device settings, this will ensure that your interview is of the best quality possible. \n\n Please turn on your:"
                            }
                          </Typography>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CheckCircleIcon
                              sx={{ marginRight: "5px", fontSize: "12px" }}
                            />
                            <Typography>Webcam</Typography>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CheckCircleIcon
                              sx={{ marginRight: "5px", fontSize: "12px" }}
                            />
                            <Typography>Microphone</Typography>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CheckCircleIcon
                              sx={{ marginRight: "5px", fontSize: "12px" }}
                            />
                            <Typography>Speakers</Typography>
                          </div>
                          {/* <Button variant="contained" sx={{ justifyContent: "center", mr: 1, position: 'absolute !important', bottom: '20px', left: '670px'  }} className="primaryBtn" >
                                                            Start
                                                        </Button> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {activeStep == 1 && (
                  <Grid
                    item
                    sm={9}
                    lg={12}
                    xs={12}
                    sx={{
                      padding: "40px 10px 10px 10px ",
                      minHeight: { sm: "350px", xs: "200px" },
                    }}
                  >
                    <SpeedTest setIsButton={setIsButton} />
                  </Grid>
                )}
                {activeStep == 2 && (
                  <Grid
                    item
                    sm={9}
                    lg={12}
                    xs={12}
                    sx={{
                      textAlign: "center",
                      justifyContent: "center",
                      padding: "10px 20px ",
                      minHeight: { sm: "350px", xs: "200px" },
                    }}
                  >
                    {recordingState ? (
                      <Typography
                        sx={{ fontSize: "14px", margin: "10px 0px 15px 0px" }}
                      >
                        <img
                          style={{ margin: "10px 10px -5px 10px" }}
                          src={RecordingState}
                        />
                        Recording...
                      </Typography>
                    ) : (
                      <>
                        <CheckCircleIcon
                          sx={{ color: "#299D51", margin: "10px 0px 0px 0px" }}
                          fontSize="large"
                        />
                        <Typography
                          align="center"
                          sx={{
                            fontSize: { sm: "20px", xs: "16px" },
                            color: "#299D51",
                            margin: "0px 0px 10px 0px",
                          }}
                        >
                          Audio Test Successfully Completed
                        </Typography>
                        {/* <Typography sx={{ fontSize: "14px", marginTop: "15px" }}>
                                                    <ReplayIcon onClick={audioTest} style={{ cursor: "pointer", height: '20px', width: '20px' }} /> &nbsp;<span style={{ position: 'absolute' }}>Retry</span>
                                                </Typography> */}
                        {/* <Button variant="contained" className='secondaryBtn' onClick={audioTest}>Retest</Button> */}
                      </>
                    )}
                    {showAudio && recordingState && (
                      <>
                        <Button
                          variant="contained"
                          className="primaryBtn"
                          onClick={() => {
                            stopRecordingFunc();
                            setRecordingState(false);
                          }}
                        >
                          Stop Recording
                        </Button>
                        <br />
                        <br />
                      </>
                    )}
                    <Typography
                      sx={{ fontSize: "14px", margin: "0px 0px 10px 0px" }}
                    >
                      {recordingState
                        ? "Audio recording is in progress, please review the audio afterwards"
                        : "Audio recording is completed, please review the audio below:"}
                    </Typography>
                    {!recordingState && <audio src={mediaBlobUrl} controls />}{" "}
                    <br />
                    {!recordingState && (
                      <Button
                        variant="contained"
                        className="primaryBtn"
                        style={{
                          marginTop: "15px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={audioTest}
                      >
                        <ReplayIcon
                          sx={{
                            cursor: "pointer",
                            height: "15px",
                            width: "15px",
                          }}
                        />{" "}
                        Retry
                      </Button>
                    )}
                  </Grid>
                )}
                {activeStep == 3 && (
                  <Grid
                    item
                    sm={9}
                    lg={12}
                    xs={12}
                    sx={{
                      textAlign: "center",
                      justifyContent: "center",
                      padding: "10px 20px ",
                      minHeight: { sm: "350px", xs: "200px" },
                      color: "#262626",
                    }}
                  >
                    {/* <Button variant="contained" className='secondaryBtn' onClick={videoTest}>Start the Video Test</Button><br /> */}
                    <video
                      style={{
                        height: "150px",
                        width: "225px",
                        transform: "scaleX(-1)",
                        margin: "10px 0px 0px 0px",
                      }}
                      ref={videoRef}
                    ></video>
                    <Typography sx={{ fontSize: "14px", margin: "15px 0px" }}>
                      If you can see your face below this text your webcam is
                      working properly. You Can go for next Step.
                    </Typography>
                    {/* <Typography sx={{ fontSize: "14px", margin: "15px 0px" }}>
                                            <ReplayIcon onClick={videoTest} style={{ cursor: "pointer", height: '20px', width: '20px' }} /> &nbsp;<span style={{ position: 'absolute' }}>Retry</span>
                                        </Typography> */}
                    <Button
                      variant="contained"
                      className="primaryBtn"
                      style={{ justifyContent: "center", alignItems: "center" }}
                      onClick={videoTest}
                    >
                      <ReplayIcon
                        sx={{
                          cursor: "pointer",
                          height: "15px",
                          width: "15px",
                        }}
                      />{" "}
                      Retry
                    </Button>
                    {/* <br />  */}
                  </Grid>
                )}
              </Grid>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: "1px solid #bfbfbf",
                  padding: " 10px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {/* <Box sx={{ display: "flex", marginBottom: "10px" }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <LiveHelpIcon sx={{ fontSize: '18px', color: "#000", marginRight: '3px' }} />
                                        <Link href="https://www.google.com/" target='_blank' mr={2} sx={{ fontSize: "12px", color: "#000" }}>Contact Support</Link>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <SupportAgentIcon sx={{ fontSize: '18px', color: "#000", marginRight: '3px' }} />
                                        <Link href="/Faq" target='_blank' mr={2} sx={{ fontSize: "12px", color: "#000" }}>FAQ</Link>
                                    </Box>
                                </Box> */}
                {/* <InfoIcon/> */}
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={isButton}
                  className="primaryBtn"
                  style={{ marginTop: "-150px", float: "right", left: "670px" }}
                >
                  {activeStep === 0 ? "Start Test" : "Next Test"}
                  {/* <ArrowForwardIcon sx={{ fontSize: '18px', marginLeft: '5px' }} /> */}
                </Button>
              </Box>
            </React.Fragment>
          )}
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            // sx={{margin : '0px 0px 20px 0px'}}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </Grid>
    </Dialog>
  );
}
