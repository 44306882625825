import "./App.css";
import Header from "./page/layout/Header";
import Footer from "./page/layout/Footer";
import React, { useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import Faq from "./page/practise/Faq";
import QuestionAnswer from "./page/practise/QuestionAnswer";
import Interview from "./page/practise/Interview";
import Review from "./page/practise/Review";
import PracticeInterview from "./page/practise/PracticeInterview";
import MainContainer from "./page/main-Container/MainContainer";
import appContext from "./context/AppContext";
import FinalQuestionAnswer from "./page/practise/FinalQuestionAnswer";
import ReviewPractice from "./page/practise/ReviewPractice";
import { Divider } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CircularProgress } from "@mui/material";

const darkTheme = createTheme({
  palette: {
    // mode: 'dark',
    // primary: deepOrange,
    // divider: deepOrange[700],
    background: {
      default: "#282a2e",
      paper: "#282a2e",
    },
    text: {
      primary: "#fff",
      // secondary: grey[500],
    },
    typography: {
      subtitle1: {
        fontSize: 33,
        // color: '#ffffff'
      },
    },
    // }),
  },
});

const whiteTheme = createTheme({
  palette: {
    mode: "light",
  },
});

function App() {
  const AppContext = useContext(appContext);
  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {
        let token = sessionStorage.getItem("ainterviewtoken");
        if (token) AppContext?.setToken(token);
      }
    }
    AppContext?.setThemeMode(true);
  }, []);

  return (
    <div className={AppContext.isDarkMode ? "dark-theme" : "white-theme"}>
      <ThemeProvider theme={AppContext?.isDarkMode ? darkTheme : whiteTheme}>
        <CssBaseline />
        <Header />
        {/* {!AppContext?.interview_data &&
            // <div style={{ "marginTop": "90px", "marginLeft": "20px" }}>
            //   <h2>Hello, welcome to the <b>airec.io</b> AI-Powered Recruitment Platform</h2>
            //   <h5><span style={{ color: 'red' }}>Something wrong!</span> please contact administrator.</h5>
            // </div>
            <CircularProgress/>
          } */}
        {!AppContext?.interview_data && (
          <div
            style={{
              width: "100px",
              height: "100px",
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              margin: "auto",
            }}
          >
            <CircularProgress size={"4rem"} color="primary" />
          </div>
        )}
        <Routes>
          <Route path="/:token" element={<MainContainer />} />
          <Route path="/Faq" element={<Faq />} />
          <Route
            path="/FinalQuestionAnswer"
            element={<FinalQuestionAnswer />}
          />
          <Route path="/QuestionAnswer" element={<QuestionAnswer />} />
          <Route path="/Interview" element={<Interview />} />
          <Route path="/PracticeInterview" element={<PracticeInterview />} />
          <Route path="/review" element={<Review />} />
          <Route path="/ReviewPractice" element={<ReviewPractice />} />
        </Routes>
        {/* {AppContext?.interview_data && <Footer />} */}
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
