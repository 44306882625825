import { Box, Grid, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useReactMediaRecorder } from "react-media-recorder";
import CardActions from '@mui/material/CardActions';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import WaitingState from '../../images/Waiting_state.svg'
import RecordingState from '../../images/Recording_state.svg'

const QandA = ({ saveAnswer, question, questionNumber, isLastQuestion, totalQuestionCount, callLoader }) => {


    function MyTimer({ expiryTimestamp }) {
        const {
            seconds,
            minutes,
        } = useTimer({
            expiryTimestamp, onExpire: () => {
                setShowTimer(false);
                startRecording();
                setSkipButton(false)
            }
        });


        return (
            <Typography style={{ fontSize: '20px', }}>{minutes}:{seconds}</Typography>
        );
    }
    function MyTimerAns({ expiryTimestamp }) {
        const {
            seconds,
            minutes,
        } = useTimer({
            expiryTimestamp, onExpire: () => {
                stopRecording();
                callLoader();
                setShowTimer(true);
                setSkipButton(true)
                if (isLastQuestion) {
                    navigate('/ReviewPractice');
                    cameraClose();
                }
            }
        });


        return (
            <Typography style={{ fontSize: '20px', }}>{minutes}:{seconds}</Typography>
        );
    }



    const [showTimer, setShowTimer] = useState(true);
    const navigate = useNavigate();
    const { startRecording, stopRecording } = useReactMediaRecorder({ video: question?.screen_share === "false" ? true : false, screen: question?.screen_share === "true" ? true : false, onStop: () => { saveAnswer() } });
    const videoRef = React.useRef(null);
    const [videoStream, setVideoStream] = useState();
    const [skipButton, setSkipButton] = useState(true)
    const timeQue = new Date();
    timeQue.setSeconds(timeQue.getSeconds() + parseInt(question?.reading_time));

    const timeAns = new Date();
    timeAns.setSeconds(timeAns.getSeconds() + parseInt(question?.time_duration));

    useEffect(() => {
        play();
    }, []);

    const play = () => {
        navigator.mediaDevices.getUserMedia({ video: true, audio: false })
            .then(stream => {
                let video = videoRef.current;
                video.srcObject = stream;
                video.play();
                setVideoStream(stream);
            })
    }
    const cameraClose = () => {
        videoStream.getTracks().forEach(function (track) {
            track.stop()
        })
    }

    return (
        <>
            <Grid>
                <Grid spacing={2} sx={{
                    display: 'flex', width: '100%', position: "relative",
                    left: '350px', top: '50px', height:'auto'
                }} lg={12}>
                    <Grid item sm={6} style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'left',
                        width: '50%', alignItems: 'left',
                        left: '300px', top: '200px'
                    }} className="videoCard">
                        <Typography  sx={{ fontWeight: "400", "paddingTop": "10px" }}>{`Question #${questionNumber + 1} / ${totalQuestionCount}`}
                        </Typography>
                        <Typography variant='h5' sx={{ fontWeight: "600", "paddingTop": "10px" }}> {question?.title}
                        </Typography>
                        <Box mt={2}>
                            <video style={{ height: "160px", width: '210px', borderRadius: 20, border: !showTimer ? '1px solid #cc0000' : '' }} ref={videoRef}></video>
                        </Box>
                        <Typography>
                            {showTimer? <div><img src={WaitingState} style={{ width: '20px', height: '20px'}}/> Waiting...</div> : <div><img src={RecordingState}/> Recording...</div>}
                        </Typography>
                    </Grid>
                    {/* <Grid sm={6}> */}
                        <Box className='timer'>
                            {/* {showTimer ? <Box sx={{ textAlign: "center", background: "#009900", padding: "10px", color: "#fff", width: "150px" }}>
                                <Typography style={{ margin: "5px 0px" }}> Time to read</Typography>
                                <MyTimer expiryTimestamp={timeQue} />
                            </Box>
                                :
                                <Box sx={{ textAlign: "center", background: " #cc0000", padding: "10px", color: "#fff", width: "150px" }}>
                                    <Typography style={{ margin: "5px 0px" }}> Time to answer</Typography>
                                    <MyTimerAns expiryTimestamp={timeAns} />
                                </Box>
                            } */}
                            {showTimer ? <Box sx={{ textAlign: "center", background: "#202020", padding: "10px", color: "#fff", width: "170px", marginTop: '30px', borderRadius: '12px' }}>
                                <Typography style={{ margin: "5px 0px" }}> Time to read</Typography>
                                <MyTimer expiryTimestamp={timeQue} />
                            </Box> :
                                <Box sx={{ textAlign: "center", background: "#cc0000", padding: "10px", color: "#fff", width: "170px", marginTop: '30px', borderRadius: '12px' }}>
                                    <Typography style={{ margin: "5px 0px" }}> Time to answer</Typography>
                                    <MyTimerAns expiryTimestamp={timeAns} />
                            </Box>}
                        </Box>
                    {/* </Grid> */}
                </Grid>
                <CardActions sx={{ justifyContent: 'end', width: "100%", pb: 0, marginLeft: '10px' }}>
                    {isLastQuestion
                        ? <Button disabled={skipButton} variant="contained" sx={{ mr: 1 }} className="primaryBtn" onClick={() => { cameraClose(); stopRecording(); navigate('/ReviewPractice'); }}>
                            Submit Test
                        </Button>
                        : <Button disabled={skipButton} variant="contained" sx={{ mr: 1, }} className="primaryBtn" onClick={() => { stopRecording(); setShowTimer(true); setSkipButton(true); callLoader(); }}>
                            Save & Next
                            <ArrowForwardIcon sx={{ fontSize: '18px', marginLeft: '5px' }} />
                        </Button>
                    }
                </CardActions>
            </Grid >
            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', "line-height": "25px", "margin-top": "5px" }}>
                <span className='lowerTextLeft'>Feedback or suggestions: hello@airec.io</span>
                <span className='lowerTextLeft'>Note: Interviews audio and videos will be saved within our servers for a period of 3 months only</span>
            </Box> */}
        </>
        // <Grid>

        //     <Grid spacing={2} sx={{
        //         display: 'flex', width: '100%', position: "relative"
        //     }}>
        //         <Grid item lg={12} style={{
        //             display: 'flex', flexDirection: 'column', justifyContent: 'center',
        //             width: '100%', alignItems: 'center',
        //         }} className="videoCard">
        //             <Typography variant='h5' sx={{ color: "#23272A", fontWeight: "600", "paddingTop": "10px" }}>{questionNumber + 1} : {question?.title} ?
        //             </Typography>
        //             <Box mt={2}>
        //                 <video style={{ height: "210px", borderRadius: 20, transform: 'scaleX(-1)' }} ref={videoRef}></video>
        //             </Box>

        //         </Grid>
        //         <Box className='timer'>
        //             {showTimer ? <Box sx={{ textAlign: "center", background: "#009900", padding: "10px", color: "#fff", width: "150px" }}>
        //                 <Typography style={{ margin: "5px 0px" }}> Time to read</Typography>
        //                 <MyTimer expiryTimestamp={timeQue} />
        //             </Box>
        //                 :
        //                 <Box sx={{ textAlign: "center", background: " #cc0000", padding: "10px", color: "#fff", width: "150px" }}>
        //                     <Typography style={{ margin: "5px 0px" }}> Time to answer</Typography>
        //                     <MyTimerAns expiryTimestamp={timeAns} />
        //                 </Box>
        //             }
        //         </Box>
        //     </Grid>
        //     <CardActions sx={{ justifyContent: 'end', width: "100%" }}>
        //         {isLastQuestion
        //             ? <Button disabled={skipButton} variant="contained" sx={{ mr: 1 }} className="primaryBtn" onClick={() => { cameraClose(); stopRecording(); navigate('/ReviewPractice'); }}>
        //                 Submit Test
        //             </Button>
        //             : <Button disabled={skipButton} variant="contained" sx={{ mr: 1, }} className="primaryBtn" onClick={() => { stopRecording(); setShowTimer(true); setSkipButton(true); callLoader(); }}>
        //                 Save & Next
        //                 <ArrowForwardIcon sx={{ fontSize: '18px', marginLeft: '5px', pb: 0 }} />
        //             </Button>
        //         }
        //     </CardActions>
        //     <Box sx={{ display: 'flex', justifyContent: 'space-between', "line-height": "25px", "margin-top": "5px" }}>
        //         <span className='lowerTextLeft'>Feedback or suggestions: hello@airec.io</span>
        //         <span className='lowerTextLeft'>Note: Interviews audio and videos will be saved within our servers for a period of 3 months only</span>
        //     </Box>
        // </Grid>
    )
}


export default QandA