import {
    Box,
    Grid,
    Typography,
    Button,
    Paper,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Card,
    CardActionArea,
    TextareaAutosize,
    TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import CardActions from "@mui/material/CardActions";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import WaitingState from "../../images/Waiting_state.svg";
import RecordingState from "../../images/Recording_state.svg";
import appContext from "../../context/AppContext";
import Timer from "./Timer";
import Editor from '@monaco-editor/react';

const FinalQandA = ({
                        saveAnswer,
                        question,
                        questionNumber,
                        totalQuestionCount,
                        isLastQuestion,
                        callLoader,
                    }) => {

    function handleEditorChange(value, event) {
        setSelectedAnswer(value);
        selectedAnswerRef.current = value;
    }

    function handleEditorDidMount(editor, monaco) {

    }

    function handleEditorWillMount(monaco) {
    }

    function handleEditorValidation(markers) {
        // model markers
        // markers.forEach(marker => console.log('onValidate:', marker.message));
    }

    function MyTimer({ expiryTimestamp }) {
        const { seconds, minutes } = useTimer({
            expiryTimestamp,
            onExpire: () => {
                setShowTimer(false);
                startRecording();
                setSkipButton(false);
                timeQue.setSeconds(0);
            },
        });

        // return (
        //   <Typography style={{ fontSize: "20px" }}>
        //     {minutes}:{seconds}
        //   </Typography>
        // );
        return (
            <span style={{ fontSize: "20px" }}>
        {minutes}:{seconds}
      </span>
        );
    }

    function MyTimerAns({ expiryTimestamp }) {
        const { seconds, minutes } = useTimer({
            expiryTimestamp,
            onExpire: () => {
                stopRecording();
                callLoader();
                setShowTimer(false);
                setSkipButton(true);
                if (isLastQuestion) {
                    navigate("/review");
                    cameraClose();
                }
            },
        });

        return (
            <span style={{ fontSize: "20px" }}>
        {minutes}:{seconds}
      </span>
        );
    }

    const AppContext = useContext(appContext);
    const [showTimer, setShowTimer] = useState(true);
    const [selectedAnswer, setSelectedAnswer] = useState("");
    const navigate = useNavigate();
    const { startRecording, stopRecording } = useReactMediaRecorder({
        video: true,
        screen: question?.screen_share === "true" ? true : false,
        onStop: (blobUrl) => {
            if (question?.question_type === "audio/video") {
                // console.log("vivz blobUrl ", blobUrl);
                saveAnswer(question, blobUrl);
            } else {
                // console.log(
                //   "vivz selectedAnswer stop",
                //   selectedAnswer,
                //   selectedAnswerRef
                // );
                saveAnswer(question, selectedAnswerRef.current);
            }
        },
    });
    // const { startRecording, stopRecording } = useReactMediaRecorder({ video: true, screen: false, onStop: (blobUrl) => { saveAnswer(question, blobUrl); } });
    const videoRef = React.useRef(null);
    const selectedAnswerRef = React.useRef("");

    const [videoStream, setVideoStream] = useState();
    const [skipButton, setSkipButton] = useState(true);
    // const [isRecording, setIsRecording] = useState(false);
    const timeQue = new Date();
    timeQue.setSeconds(timeQue.getSeconds() + parseInt(question?.reading_time));

    const timeAns = new Date();
    timeAns.setSeconds(timeAns.getSeconds() + parseInt(question?.time_duration));
    // const queTimer = useTimer({
    //   expiryTimestamp: timeQue,
    //   onExpire: () => {
    //     setShowTimer(false);
    //     startRecording();
    //     setSkipButton(false);
    //     timeQue.setSeconds(0);
    //   },
    // });
    // const ansTimer = useTimer({
    //   expiryTimestamp: timeAns,
    //   onExpire: () => {
    //     stopRecording();
    //     callLoader();
    //     setShowTimer(false);
    //     setSkipButton(true);
    //     if (isLastQuestion) {
    //       navigate("/review");
    //       cameraClose();
    //     }
    //   },
    // });
    // const readingTimer = () => {
    //   return (
    //     queTimer.minutes !== undefined &&
    //     queTimer.seconds !== undefined && (
    //       <span style={{ fontSize: "20px" }}>
    //         {`${queTimer.minutes}:${queTimer.seconds}`}
    //       </span>
    //     )
    //   );
    // };

    // const answerTimer = () => {
    //   return (
    //     ansTimer.minutes !== undefined &&
    //     ansTimer.seconds !== undefined && (
    //       <span style={{ fontSize: "20px" }}>
    //         {`${ansTimer.minutes}:${ansTimer.seconds}`}
    //       </span>
    //     )
    //   );
    // };
    const onReadingTimeExpire = () => {
        // console.log("inside tmer");
        setShowTimer(false);
        startRecording();
        setSkipButton(false);
    };
    const onAnswerTimeExpire = () => {
        stopRecording();
        callLoader();
        setShowTimer(false);
        setSkipButton(true);
        if (isLastQuestion) {
            navigate("/review");
            cameraClose();
        }
    };
    useEffect(() => {
        if (question?.question_type === "audio/video") {
            play();
        } else {
            // cameraClose();
        }

        // return () => {
        //   stopRecording();
        //   cameraClose();
        // };
    }, [question?.question_type]);

    useEffect(() => {
        selectedAnswerRef.current = selectedAnswer;
    }, [selectedAnswer]);
    const play = () => {
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: false })
            .then((stream) => {
                let video = videoRef.current;
                video.srcObject = stream;
                video.play();
                setVideoStream(stream);
            });
    };
    const cameraClose = () => {
        videoStream?.getTracks().forEach(function (track) {
            track.stop();
        });
    };
    const getAudioVideoTypeQuestionUI = () => {
        return (
            <Grid container spacing={2}>
                {/* <Grid
          // spacing={2}
          sx={{
            display: "flex",
            width: "100%",
            position: "relative",
            left: "350px",
            top: "50px",
            textAlign: "center",
            height: "auto",
          }}
          // lg={24}
          // xs={8}
        > */}
                <Grid
                    item
                    sm={6}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "left",
                        width: "50%",
                        // alignItems: "center",
                        // left: "300px",
                        // right: "200px",
                        // top: "200px",
                        position: "relative",
                        left: "2%",
                    }}
                    className="videoCard"
                >
                    <Typography sx={{ fontWeight: "400", paddingTop: "10px" }}>
                        {`Question #${questionNumber + 1} / ${totalQuestionCount}`}
                    </Typography>
                    <Typography
                        variant="h5"
                        sx={{ fontWeight: "600", paddingTop: "10px", left: "10%" }}
                    >
                        {question?.title}
                    </Typography>
                    <Box mt={20}>
                        <video
                            style={{
                                height: "260px",
                                width: "350px",
                                borderRadius: 20,
                                border: !showTimer ? "1px solid #cc0000" : "",
                            }}
                            ref={videoRef}
                        ></video>
                    </Box>
                    <Typography>
                        {showTimer ? (
                            <div>
                                <img
                                    src={WaitingState}
                                    style={{ width: "20px", height: "20px" }}
                                />
                                <span
                                    style={{
                                        top: "-4px",
                                        margin: "5px 5px 5px 5px",
                                        position: "relative",
                                    }}
                                >
                  Waiting...
                </span>
                            </div>
                        ) : (
                            <div>
                                <img src={RecordingState} />
                                <span
                                    style={{
                                        top: "-4px",
                                        margin: "5px 5px 5px 5px",
                                        position: "relative",
                                    }}
                                >
                  Recording...
                </span>
                            </div>
                        )}
                    </Typography>
                </Grid>
                {/* <Box className="timer"> */}
                <Grid
                    item
                    sm={6}
                    style={{
                        alignItems: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        width: "50%",
                        // alignItems: "left",
                        left: "200px",
                        top: "200px",
                    }}
                    // className="videoCard"
                >
                    {/* <Box
            sx={{
              textAlign: "center",
              background: showTimer ? "#202020" : "#cc0000",
              padding: "10px",
              color: "#fff",
              width: "170px",
              height: "100px",
              marginTop: "15px",
              borderRadius: "12px",
            }}
            style={{
              right: "50%",
            }}
          >
            <Typography style={{ margin: "5px 0px" }}>
              {" "}
              {showTimer ? "Time to read" : "Time to answer"}
            </Typography> */}
                    <Timer
                        readingTime={question?.reading_time}
                        answerTime={question?.time_duration}
                        questionType={question?.question_type}
                        onReadingTimerExpire={onReadingTimeExpire}
                        onAnswerTimerExpire={onAnswerTimeExpire}
                        sxObject={{
                            textAlign: "center",
                            background: showTimer ? "#202020" : "#cc0000",
                            padding: "10px",
                            color: "#fff",
                            width: "170px",
                            height: "100px",
                            marginTop: "15px",
                            borderRadius: "12px",
                        }}
                        styleObject={{
                            right: "50%",
                        }}
                        spanStyle={{
                            fontSize: "20px",
                            margin: "5px 0px",
                        }}
                    />
                    {/* </Box> */}
                    {isLastQuestion ? (
                        <Button
                            disabled={skipButton}
                            variant="contained"
                            style={{
                                width: "18%",
                                height: "50px",
                                top: "61%",
                                // bottom: "0px",
                                // position: "absolute",
                            }}
                            // sx={{ mr: 1 }}
                            className="primaryBtn"
                            onClick={() => {
                                cameraClose();
                                stopRecording();
                                navigate("/review");
                            }}
                        >
                            Submit Test
                        </Button>
                    ) : (
                        <Button
                            disabled={skipButton}
                            variant="contained"
                            style={{
                                width: "18%",
                                height: "50px",
                                top: "61%",
                                // bottom: "0px",
                                // position: "absolute",
                            }}
                            // sx={{ mr: 1 }}
                            className="primaryBtn"
                            onClick={() => {
                                stopRecording();
                                setShowTimer(true);
                                setSkipButton(true);
                                callLoader();
                            }}
                        >
                            Save & Next
                            <ArrowForwardIcon sx={{ fontSize: "18px", marginLeft: "5px" }} />
                        </Button>
                    )}
                    {/* </Grid> */}
                </Grid>
            </Grid>
        );
    };

    // const handleOptionChange = (event) => {
    //   setSelectedAnswer(event.target.value);
    // };
    const handleOptionChange = (event) => {
        const newValue = event.target.value;
        setSelectedAnswer(newValue);
        selectedAnswerRef.current = newValue; // Update ref
    };
    const handlePaste = (event) => {
        event.preventDefault();
    }

    const getMCQsTypeQuestionUI = () => {
        return (
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={7}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        background: AppContext?.isDarkMode ? "#2a2d31" : "#ffffff",
                        boxShadow: AppContext?.isDarkMode
                            ? "5px 5px 5px 5px #2a2d31"
                            : "0 3px 10px rgb(0 0 0 / 0.2)",
                        alignItems: "left",
                        top: "200px",
                    }}
                    className="videoCard"
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            // p: 1,
                            // m: 1,
                            marginBottom: "15px",
                            borderRadius: 1,
                        }}
                    >
                        <Box sx={{ fontWeight: "400", fontSize: "20px" }}>
                            {`Question #${questionNumber + 1} / ${totalQuestionCount}`}
                        </Box>{" "}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderRadius: 1,
                            }}
                        >
                            {/* <Box
                sx={{
                  textAlign: "center",
                  background: showTimer ? "#202020" : "#cc0000",
                  color: "#fff",
                  width: "240px",
                  height: "55px",
                  borderRadius: "12px",
                  float: "right",
                  right: "10px",
                  position: "relative",
                }}
              >
                <span style={{ fontSize: "20px", margin: "0px 10px 0px 0px" }}>
                  {showTimer ? "Time to read" : "Time to answer"}
                </span>
                {showTimer ? readingTimer() : answerTimer()}
              </Box> */}
                            <Timer
                                readingTime={question?.reading_time}
                                answerTime={question?.time_duration}
                                questionType={question?.question_type}
                                onReadingTimerExpire={onReadingTimeExpire}
                                onAnswerTimerExpire={onAnswerTimeExpire}
                                sxObject={{
                                    textAlign: "center",
                                    background: showTimer ? "#202020" : "#cc0000",
                                    color: "#fff",
                                    width: "240px",
                                    height: "55px",
                                    borderRadius: "12px",
                                    float: "right",
                                    right: "10px",
                                    position: "relative",
                                }}
                                spanStyle={{ fontSize: "20px", margin: "0px 10px 0px 0px" }}
                            />
                        </Box>
                    </Box>
                    <Typography
                        variant="h5"
                        sx={{ fontWeight: "600", paddingTop: "10px" }}
                    >
                        {" "}
                        {question?.title}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={5}
                    style={{
                        background: AppContext?.isDarkMode ? "#2a2d31" : "#ffffff",
                        boxShadow: AppContext?.isDarkMode
                            ? "5px 5px 5px 5px #2a2d31"
                            : "0 3px 10px rgb(0 0 0 / 0.2)",
                    }}
                >
                    <FormControl
                        component="fieldset"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            overflowY: "auto",
                            maxHeight: "60vh",
                            bottom: "15px",
                        }}
                    >
                        <RadioGroup
                            aria-label="answers"
                            name="answers"
                            value={selectedAnswer}
                            onChange={handleOptionChange}
                        >
                            {question?.answers.map((option, index) => (
                                <Card
                                    sx={{
                                        height: "auto",
                                        minHeight: "100px",
                                        margin: "10px 0px",
                                        width: "98%",
                                    }}
                                >
                                    <CardActionArea>
                                        <FormControlLabel
                                            style={{
                                                margin: "auto",
                                                alignItems: "center",
                                                flex: 1,
                                            }}
                                            key={index}
                                            value={option}
                                            control={<Radio />}
                                            label={option}
                                        />
                                    </CardActionArea>
                                </Card>
                            ))}
                        </RadioGroup>
                    </FormControl>
                    {isLastQuestion ? (
                        <Button
                            disabled={skipButton}
                            variant="contained"
                            style={{
                                width: "18%",
                                height: "50px",
                                float: "right",
                                right: "10px",
                            }}
                            className="primaryBtn"
                            onClick={() => {
                                cameraClose();
                                // console.log("vivz selected answer ", selectedAnswer);
                                stopRecording();
                                // saveAnswer(question, selectedAnswer);
                                navigate("/review");
                            }}
                        >
                            Submit Test
                        </Button>
                    ) : (
                        <Button
                            disabled={skipButton}
                            variant="contained"
                            style={{
                                width: "24%",
                                height: "50px",
                                float: "right",
                                right: "10px",
                            }}
                            // sx={{ mr: 1 }}
                            className="primaryBtn"
                            onClick={() => {
                                // saveAnswer(question, selectedAnswer);
                                stopRecording();
                                setShowTimer(true);
                                setSkipButton(true);
                                callLoader();
                            }}
                        >
                            Save & Next
                            <ArrowForwardIcon sx={{ fontSize: "18px", marginLeft: "5px" }} />
                        </Button>
                    )}
                </Grid>
            </Grid>
        );
    };
    const getCodeTypeQuestionUI = () => {
        return (
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={7}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        background: AppContext?.isDarkMode ? "#2a2d31" : "#ffffff",
                        boxShadow: AppContext?.isDarkMode
                            ? "5px 5px 5px 5px #2a2d31"
                            : "0 3px 10px rgb(0 0 0 / 0.2)",
                        alignItems: "left",
                    }}
                    className="videoCard"
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "15px",
                            borderRadius: 1,
                        }}
                    >
                        <Box sx={{ fontWeight: "400", fontSize: "20px" }}>
                            {`Question #${questionNumber + 1} / ${totalQuestionCount}`}
                        </Box>{" "}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderRadius: 1,
                            }}
                        >
                            <Timer
                                readingTime={question?.reading_time}
                                answerTime={question?.time_duration}
                                questionType={question?.question_type}
                                onReadingTimerExpire={onReadingTimeExpire}
                                onAnswerTimerExpire={onAnswerTimeExpire}
                                sxObject={{
                                    textAlign: "center",
                                    background: showTimer ? "#202020" : "#cc0000",
                                    color: "#fff",
                                    width: "240px",
                                    height: "55px",
                                    borderRadius: "12px",
                                    float: "right",
                                    right: "10px",
                                    position: "relative",
                                }}
                                spanStyle={{
                                    fontSize: "20px",
                                    margin: "0px 10px 0px 0px",
                                }}
                            />
                        </Box>
                    </Box>
                    <Grid
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            overflowY: "auto",
                            maxHeight: "58vh",
                            right: "10px",
                            position: "relative",
                            padding: "0px 12px",
                        }}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: AppContext?.isDarkMode
                                    ? question?.title.replaceAll(
                                        "color: rgb(0, 0, 0);",
                                        "color: #fff;"
                                    )
                                    : question?.title,
                            }}
                        ></div>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={5}
                    // }}
                    style={{
                        background: AppContext?.isDarkMode ? "#2a2d31" : "#ffffff",
                        boxShadow: AppContext?.isDarkMode
                            ? "5px 5px 5px 5px #2a2d31"
                            : "0 3px 10px rgb(0 0 0 / 0.2)",
                    }}
                >
                    {/*<TextField*/}
                    {/*    multiline*/}
                    {/*    placeholder={'Start writing your answer here...'}*/}
                    {/*    rows={24}*/}
                    {/*    value={selectedAnswer}*/}
                    {/*    onChange={handleOptionChange}*/}
                    {/*    onPaste={handlePaste}*/}
                    {/*    style={{*/}
                    {/*        display: "flex",*/}
                    {/*        flexDirection: "column",*/}
                    {/*        overflowY: "auto",*/}
                    {/*        maxHeight: "60vh",*/}
                    {/*        bottom: "15px",*/}
                    {/*        right: "10px",*/}
                    {/*        border: "1px solid #505050"*/}
                    {/*    }}*/}
                    {/*    variant="standard"*/}
                    {/*    focused*/}
                    {/*/>*/}
                    <Editor
                        height="90vh"
                        defaultLanguage="javascript"
                        theme="vs-dark"
                        defaultValue="Start wrting your code here"
                        onChange={handleEditorChange}
                        onMount={handleEditorDidMount}
                        beforeMount={handleEditorWillMount}
                        onValidate={handleEditorValidation}
                    />
                    {isLastQuestion ? (
                        <Button
                            disabled={skipButton}
                            variant="contained"
                            style={{
                                width: "18%",
                                height: "50px",

                                float: "right",
                                right: "10px",
                            }}
                            // sx={{ mr: 1 }}
                            className="primaryBtn"
                            onClick={() => {
                                cameraClose();
                                // saveAnswer(question, selectedAnswer);
                                stopRecording();
                                navigate("/review");
                            }}
                        >
                            Submit Test
                        </Button>
                    ) : (
                        <Button
                            disabled={skipButton}
                            variant="contained"
                            style={{
                                width: "24%",
                                height: "50px",
                                float: "right",
                                right: "10px",
                            }}
                            // sx={{ mr: 1 }}
                            className="primaryBtn"
                            onClick={() => {
                                // saveAnswer(question, selectedAnswer);
                                stopRecording();
                                setShowTimer(true);
                                setSkipButton(true);
                                callLoader();
                            }}
                        >
                            Save & Next
                            <ArrowForwardIcon sx={{ fontSize: "18px", marginLeft: "5px" }} />
                        </Button>
                    )}
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            {/*{console.log("vivz render")}*/}
            {/*{console.log("vivz render,", selectedAnswer)}*/}
            {question?.question_type === "audio/video" &&
                getAudioVideoTypeQuestionUI()}
            {question?.question_type === "mcq" && getMCQsTypeQuestionUI()}
            {question?.question_type === "code" && getCodeTypeQuestionUI()}
        </>
    );
};

export default FinalQandA;
