import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import appContext from "../../context/AppContext";
import ReplayIcon from '@mui/icons-material/Replay';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//This is a speed Test Component where we are finding Bandwidth of the Internet

const SpeedTest = ({ setIsButton }) => {
    var arrTimes = [];
    var i = 0; // start
    var timesToTest = 5;
    var tThreshold = 650; //ms
    var testImage = "http://www.google.com/images/phd/px.gif"; // small image in your server
    var dummyImage = new Image();
    var isConnectedFast = false;

    const [mess, setMessage] = useState("")

    function testLatency() {
        setMessage("");
        var tStart = new Date().getTime();
        if (i < timesToTest - 1) {
            dummyImage.src = testImage + '?t=' + tStart;
            dummyImage.onload = function () {
                var tEnd = new Date().getTime();
                var tTimeTook = tEnd - tStart;
                arrTimes[i] = tTimeTook;
                testLatency();
                i++;
            };
            dummyImage.onerror = function (err) {
                setMessage("Please connect to the internet & Retry.")
            };
        } else {
            /** calculate average of array items then callback */
            var sum = arrTimes.reduce(function (a, b) { return a + b; });
            var avg = sum / arrTimes.length;
            if (avg?.toFixed(2) < tThreshold) {
                setMessage("Your Internet is working properly, you can proceed for the next test.");
                setIsButton(false);
            }
            else setMessage("Your internet speed is too slow.");
        }
    }

    useLayoutEffect(() => {
        testLatency();
    }, [])


    return (
        <div style={{textAlign: "center", justifyContent: "center", alignItems: "center"}}>
            {
            //     <div style={{                    
            //         width: '40px',
            // height: '40px',
            // position: 'absolute',
            // top: '0',
            // bottom: '0',
            // left: '0',
            // right: '0',
            // margin: 'auto auto 91px auto',
            //         textAlign: 'center'
            //     }} className="loader">Loading...</div>
                mess ? <div>
                        {mess != ("Please connect to the internet & Retry." || "Your internet speed is too slow.") && <><CheckCircleIcon sx={{ color: '#299D51', margin: '0px 0px 0px 0px'}} fontSize="large"/>
                        <Typography align='center' sx={{ fontSize: { sm: "20px", xs: "16px" }, margin: '10px 0px 0px 0px', color:'#299D51' }}>Bandwidth Test Successfully Completed</Typography></>}
                        <Typography align='center' sx={{ margin: '10px 0px 10px 0px' }}> {mess}</Typography>
                    </div> : <div style={{
                        width: '40px',
                        height: '40px',
                        position: 'absolute',
                        top: '0',
                        bottom: '0',
                        left: '0',
                        right: '0',
                        margin: 'auto auto 401px auto',
                                textAlign: 'center'
                    }} className="loader">Loading...</div>
            }



            {(mess === "Please connect to the internet & Retry." || "Your internet speed is too slow.") && 
            <Button variant="contained" className='primaryBtn' style={{ marginTop: '40px'}} onClick={() => testLatency()}>
                <ReplayIcon sx={{ cursor: "pointer", height: '15px', width: '15px' }} /> Retry
            </Button>
            }
        </div>
    )
}


export default SpeedTest