import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const Timer = ({
  readingTime,
  answerTime,
  questionType,
  onReadingTimerExpire,
  onAnswerTimerExpire,
  sxObject,
  styleObject,
  spanStyle,
}) => {
  const [totalSeconds, setTotalSeconds] = useState(readingTime);
  const [isActive, setIsActive] = useState(false);
  const [isReadingTime, setIsReadingTime] = useState(true);

  const startTimer = () => {
    setIsActive(true);
  };

  const resetTimer = () => {
    setIsActive(false);
    setIsReadingTime(true);
    setTotalSeconds(readingTime);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    let interval;
    startTimer();
    if (isActive) {
      interval = setInterval(() => {
        setTotalSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            // Switch to answer time when reading time is over
            setIsReadingTime(false);
            onReadingTimerExpire();
            return answerTime;
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [isActive, readingTime, answerTime]); // Dependency on isActive, readingTime, and answerTime

  useEffect(() => {
    if (totalSeconds === 0 && !isReadingTime) {
      onAnswerTimerExpire();
    }
  }, [totalSeconds]);
  return (
    <div>
      <Box
        // sx={{
        //   textAlign: "center",
        //   background: isReadingTime ? "#202020" : "#cc0000",
        //   padding: "10px",
        //   color: "#fff",
        //   width: "170px",
        //   height: "100px",
        //   marginTop: "15px",
        //   borderRadius: "12px",
        // }}
        // style={{
        //   right: "50%",
        // }}
        sx={sxObject}
        style={styleObject}
      >
        {/* <span style={spanStyle}>
          {" "}
          {isReadingTime ? "Time to read" : "Time to answer"}
        </span> */}
        {questionType === "audio/video" ? (
          <Typography style={spanStyle}>
            {isReadingTime ? "Time to read" : "Time to answer"}
          </Typography>
        ) : (
          <span style={spanStyle}>
            {isReadingTime ? "Time to read" : "Time to answer"}
          </span>
        )}

        <span style={{ fontSize: "20px" }}>{formatTime(totalSeconds)}</span>
      </Box>
    </div>
  );
};

export default Timer;
