import { SET_TOKEN, UPDATE_PROGRESS, SET_THEME_MODE } from "./AppType";

export default (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case SET_TOKEN:
            return {
                ...state,
                token: payload?.token,
                interview_data: payload?.interview_data,
                email: payload?.email
            };

        case UPDATE_PROGRESS:
            return {
                ...state,
                uploadProgress: payload
            };

        case SET_THEME_MODE:
            return {
                ...state,
                isDarkMode: payload
            };

        default:
            return state;
    }
};